<template>
  <div class="container-fluid mt-5">
    <div class="row my-3 justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="h3 d-none d-lg-block text-muted">Funktionstest</div>
        <div class="h3 d-block d-lg-none text-muted">Was bietet ihr an?</div>
        <div class="h1 d-none d-md-block company-color1">
          ChatBot
          <br />
        </div>
        <div class="h1 d-block d-md-none company-color1">
          ChatBot
          <br />
        </div>
        Um Fragen für Kunden zu beantworten soll ein ChatBot eingesetzt werden,
        welche divere Fragen beantworten kann.
        <br />
        <small class="text-muted">
          Gesprächsarten mit einem <strong>*</strong> sind geplant, jedoch noch
          nicht implementiert.
        </small>
        <br />
        <strong>Informationen zur Firma</strong>
        <ul>
          <li>Firma</li>
          <li>Filialen & Öffnungszeiten & Anfahrt & Parken</li>
          <li>Zahlungsmöglichkeiten (Bar, Karte, Finanzierung)</li>
          <li>*Mitarbeiter</li>
        </ul>
        <strong>Informationen zum Ausbildungssystem</strong>
        <ul>
          <li>*Ausbildungen (Führerscheine, Maßnahmen)</li>
          <li>Preise (Preisliste, Umgang mit Rabatten)</li>
          <li>
            Infos zu Unterrichtsstunden / Kurse (Wann ist der nächste Kurs
            zu...?, Finden am ... Unterrichtsstunden zu ... statt?)
          </li>
        </ul>
        <strong
          >Kundenfeedback (es werden später Daten im System erzeugt: z.B. Email
          / Bewertung etc.)</strong
        >
        <ul>
          <li>Bewertung</li>
          <li>Daten sind falsch</li>
          <li>Beschwerden</li>
          <li>Fehler im System (z.B. Anzeigefehler, Schwachstellen etc.)</li>
          <li>Corona Infektion melden</li>
        </ul>
        <strong
          >FAQ (weitere Fragen) => Nur Antworten keine weitere Funktion</strong
        >
        <ul>
          <li>
            Fragen zum Vertrag (Ausbildungsstätte wechseln[weg/zur Fahrschule],
            Abbruch der Ausbildung)
          </li>
          <li>Corona & Hygieneschutzmaßnahmen</li>
          <li>Umgang mit behinderten Menschen / Fremdsprachen</li>
          <li>Ausbildung auf Automatikfahrzeugen</li>
          <li>*Probleme mit der Kalenderintegration</li>
          <li>Fahrstunde absagen & Fehlzeiten</li>
          <li>Als Mitarbeiter bewerben</li>
          <li>Erinnerungen bzw. Benachrichtigungen *erhalten / abbestellen</li>
          <li>Wo finde ich: Fahrschüler-Cockpit / Webseite</li>
        </ul>
        <strong>Smalltalk</strong>
      </div>
    </div>
    <div class="row my-5 justify-content-center">
      <div class="col-12 col-lg-10 px-3 text-center">
        <fa-element
          client_id="fahrschule_default"
          type="form"
          form_name="chatbot_test"
        />
      </div>
    </div>
    <div class="row my-5 justify-content-center">
      <div class="col-12 col-lg-10 px-3 text-center">
        <hr class="pb-5" />
        <h3 class="font-weight-bold">Du hast Fragen?</h3>
        <span class>Wir beraten dich gerne!</span>
      </div>
    </div>
    <fa-messenger
      client_id="fahrschule_werner"
    ></fa-messenger>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      session_id: new Date().valueOf(),
    };
  },
  mounted() {
    if ("FA_Handler" in window) window.FA_Handler.refresh();
  },
};
</script>
